<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <br /><br />
          <div class="closeBtn mb-2 text-right">
            <p @click="closeCurrentTab()" class="btn m-0">
              <i class="fas fa-window-close fa-4x text-danger"></i>
            </p>
          </div>
          <h6 class="alert alert-danger" v-if="CsvError">
            Invalid format/Missing Data, Please check the excel sheet
          </h6>
          <div class="selectExcelFile d-flex align-items-center">
            <a href="/files/ezygoLessonPlanImportTemplate.xlsx" download="ezygoLessonPlanImportTemplate.xlsx">
              <b-button variant="info"  size="sm"><i class="fa fa-download mr-2"></i>Download Template Excel File</b-button>
            </a>
            <b-form-file ref="selectedExcelFile" style="display: none" v-model="excelSheet"
              placeholder="Select Excel File"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"></b-form-file>
            <b-button variant="info" v-if="excelSheet == null"  size="sm" class="mx-2" @click="selectExcelFile()"><i
                class="fas fa-file-excel mr-2 "></i>Select Excel File to Upload</b-button>

            <h6 v-else class="text-dark font-weight-bolder">
              {{ excelSheet.name }}
              <span v-if="!outcomeBtn.disabled" class="text-danger btn m-0 p-0" @click="removeExcelSheet()"><i
                  class="fas fa-trash ml-2"></i></span>
            </h6>
            <b-button v-if="excelTable.length == 0" variant="info" @click="checkExcelSheet"
              class=" ml-3" :disabled="excelSheet == null"><i class="fas fa-upload fa-2x mx-2"></i>Start Upload</b-button>
          </div>
          <div class="progressBar" v-if="loadData">
            <b-progress :value="value" :max="max" show-progress animated variant="success"></b-progress>
          </div>
          <div v-if="excelTable.length != 0">
            <div v-for="(lessonP, lessonIndex) in excelTable" :key="lessonIndex">
              <b-form-group class="text-right">
                <span class="text-danger btn p-0 m-0" @click="excelTable.splice(lessonIndex, 1)"><i
                    class="fas fa-minus-circle fa-2x"></i></span>
              </b-form-group>

              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">Si. No.:</label>
                  <div class="col-md-7">
                    <b-form-input placeholder="Si. No." v-model="lessonP.si_no"></b-form-input>
                  </div>
                </div>
              </b-form-group>


              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">Lessons:</label>
                  <div class="col-md-7">
                    <b-form-textarea placeholder="Enter Topic" rows="3" v-model="lessonP.lesson"
                      :class="{ 'is-invalid': responseErrors.lesson }"></b-form-textarea>
                    <span class="text-danger text-right" v-if="responseErrors.lesson">{{
                        responseErrors.lesson[0]
                    }}</span>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">Planned Date:</label>
                  <div class="col-md-7">
                    <b-form-input v-model="lessonP.planned_dates"
                      :class="{ 'is-invalid': responseErrors.planned_dates }"
                      placeholder="Enter planned date in DD/MM/YYYY Format. 14/02/2023,15/02/2023,..."></b-form-input>
                    <span class="text-danger" v-if="responseErrors.planned_dates">{{
                        responseErrors.planned_dates[0]
                    }}</span>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">No. of hours required</label>
                  <div class="col-md-7">
                    <b-form-input placeholder="No. of hours required"
                      :class="{ 'is-invalid': responseErrors.required_hours }" v-model="lessonP.required_hours">
                    </b-form-input>
                    <span class="text-danger" v-if="responseErrors.required_hours">{{
                        responseErrors.required_hours[0]
                    }}</span>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">Select Course Outcome:</label>
                  <div class="col-md-7">
                    <!-- <b-form-select
                      :options="courseOutcomesOptions"
                      v-model="lessonP.course_outcome_id"
                    ></b-form-select> -->
                    <multiselect v-model="lessonP.course_outcomes" :options="courseOutcomesOptions" :multiple="true"
                      :close-on-select="false" :clear-on-select="false" placeholder="Select CO's" label="text"
                      track-by="value" :preselect-first="false">
                      <template slot="selection"><span class="multiselect__single d-flex flex-wrap"
                          v-if="lessonP.course_outcomes">
                          <span style="font-size: 12px; font-weight: 600"
                            v-for="(co, coIndex) in lessonP.course_outcomes" :key="coIndex">
                            {{ co.text }}
                            <span v-if="
                              lessonP.course_outcomes.length > 1 &&
                              lessonP.course_outcomes.length - 1 != coIndex
                            ">,</span>
                          </span>
                        </span></template>
                    </multiselect>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="row">
                  <div class="col-md-2"></div>
                  <label for="" class="col-md-3">Select Module:</label>
                  <div class="col-md-7">
                    <b-form-select :options="courseModulesOptions" v-model="lessonP.course_module_id"></b-form-select>
                  </div>
                </div>
              </b-form-group>

              <b-form-group label="Cognitive Levels:">
                <multiselect v-model="lessonP.cognitive_levels" :options="cognitive_levels_options" :multiple="true"
                  :close-on-select="false" :clear-on-select="false" placeholder="Select Cognitive levels"
                  :preselect-first="false">
                </multiselect>
              </b-form-group>

            </div>
            <b-form-group>
              <b-button @click="createLesson()" v-html="outcomeBtn.text" class="d-flex align-items-center"
                variant="primary" size="md" :disabled="outcomeBtn.disabled">{{ outcomeBtn.text }}</b-button>
            </b-form-group>
          </div>
          <div class="sampleCsv" v-if="excelTable.length == 0">
            <p class=" d-flex align-items-baseline">
              <b class="text-dark mr-2">Note: </b>Please provide serial number, topic, planned dates(comma hyphen
              separated), number of
              hours required, course outcome name(s) (separated by commas, e.g, CO1,CO2,CO3,CO4), and course module
              name.
              <br>Ensure that you enter the course outcome name and course module name exactly as they appear in the
              Course Outcome and Course Syllabus List. <br>Incorrect data will be automatically removed, and you will
              need so select them manually from the dropdown list on the next page. <br>A sample image is provided below
              for reference:

            </p>
            <img src="../../../../../assets/images/LessonPlanImage.png" alt="" class="img-fluid" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import readXlsxFile from "read-excel-file";
// import moment from "moment";
// import moment from 'moment';
export default {
  async created() {
    await this.getCourseOutcomes();
    await this.getCourseModules();
  },
  data() {
    return {
      loadData: false,
      value: 25,
      max: 100,
      excelSheet: null,
      excelTable: [],
      checkCsv: [],
      CsvError: false,
      outcomeBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      courseOutcomesOptions: [],
      courseModulesOptions: [],
      courseOutcomes: {},
      courseModules: {},
      cognitive_levels_options: ['Remembering', 'Understanding', 'Applying', 'Analyzing', 'Evaluating', 'Creating', 'Knowledge'],
    };
  },

  methods: {
    async createLesson() {
      const courseId = this.$route.params.course_id;
      this.outcomeBtn.text =
        `This process will take a few seconds, please wait` +
        `<div class="spinner-grow text-white ml-3"></div>`;
      this.outcomeBtn.disabled = true;

      for (const lessonDetail in this.excelTable) {
        let co = [];
        if (this.excelTable[lessonDetail].course_outcomes != null) {
          await this.excelTable[lessonDetail].course_outcomes.forEach((outcome) => {
            co.push(outcome.value);
          });
        }
        let lessonData = {
          si_no: this.excelTable[lessonDetail].si_no,
          lesson: this.excelTable[lessonDetail].lesson,
          planned_dates: this.excelTable[lessonDetail].planned_dates,
          required_hours: this.excelTable[lessonDetail].required_hours,
          course_id: courseId,
          course_outcomes: co,
          course_module_id: this.excelTable[lessonDetail].course_module_id,
          cognitive_levels: this.excelTable[lessonDetail].cognitive_levels,
        };
        let lessonPlan = await this.storeLessonPlan(lessonData);
        if (lessonPlan) {
          this.$toast.info(
            `${this.excelTable[lessonDetail].lesson} is created successfuly`,
            {
              position: "top-right",
              duration: 3000,
            }
          );

          if (lessonDetail == this.excelTable.length - 1) {
            this.responseSuccess["created"] = "Lessons Created";
            this.$toast.success("Lesson Plans are successfully created.", {
              position: "top",
              duration: 0,
            });

            this.clearResponse();
          }
        }
        if (!lessonPlan) {
          this.$toast.warning(Object.keys(this.responseErrors)[0] + " " + "is required", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse();
        }
      }
    },

    async storeLessonPlan(lessonData) {
      const url = this.$store.getters.getAPIKey.lessonplans;
      let status = null;
      await axios
        .post(url, lessonData)
        .then(() => {
          status = true;
          // console.log(response);
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          status = false;
          // console.log(error.response);
        });
      return status;
    },

    clearResponse() {
      this.outcomeBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess["created"]) {
          this.removeExcelSheet();
          this.$toast.clear();
          // this.closeCurrentTab();
        }
        this.outcomeBtn.disabled = false;
        this.responseErrors = {};
        this.responseSuccess = {};
      }, 5000);
    },
    selectExcelFile() {
      this.$refs.selectedExcelFile.$refs.input.click();
    },
    removeExcelSheet() {
      this.excelTable = [];
      this.excelSheet = null;
    },
    async checkExcelSheet() {
      this.$forceUpdate();
      this.loadData = true;
      this.checkCsv = [];
      this.courseOutcomes = {};
      this.courseModules = {};
      await this.excelSheet;

      this.excelTable = [];

      await readXlsxFile(this.excelSheet).then((csv) => {
        this.value = this.value + 10;
        this.checkCsv = csv;
      });
      let checkValidation;
      await this.checkCsv.forEach((csv) => {
        if (csv.length > 7 || csv.length < 6) {
          return (checkValidation = true);
        }
        // if (this.checkCsv[this.checkCsv.length - 1].length <= 6) {
        //   return (checkValidation = false);
        // }
      });

      if (checkValidation) {
        this.value = this.value + 10;
        this.CsvError = true;
        this.loadData = false;
        this.value = 50;
        this.removeExcelSheet();
        setTimeout(() => {
          this.CsvError = false;
        }, 5000);
      } else {
        this.value = this.value + 20;

        await this.checkCsv.forEach((cOData, coIndex) => {
          let excelArray = [];
          if (cOData[4] != null && cOData[4] != '')
            if (cOData[4].length != 0) {
              excelArray = cOData[4].split(",");
            }
          this.$set(this.courseOutcomes, coIndex, {});
          this.$set(this.courseOutcomes[coIndex], "id", []);
          this.$set(this.courseOutcomes[coIndex], "validation", false);
          this.$set(this.courseOutcomes[coIndex], "excelOutcomeData", excelArray);

        });

        await this.checkCsv.forEach((cOData, coIndex) => {
          this.courseOutcomesOptions.forEach((outcomeFromOption) => {
            this.courseOutcomes[coIndex]["excelOutcomeData"].forEach(
              (outcomeFromExcel) => {
                if (
                  this.courseOutcomes[coIndex] &&
                  `${outcomeFromExcel}`
                    .toLowerCase()
                    .match(outcomeFromOption.text.toLowerCase())
                ) {
                  this.courseOutcomes[coIndex].validation = true;
                  this.courseOutcomes[coIndex]["id"].push(outcomeFromOption);
                }
              }
            );
          });
        });

        this.value = this.value + 5;

        await this.checkCsv.forEach((cOData, coIndex) => {

          if (cOData[5] && cOData[5].length != 0) {
            this.courseModules[coIndex] = {};
            this.courseModulesOptions.forEach((cModule) => {
              if (`${cOData[5]}`.toLowerCase().match(cModule.text.toLowerCase())) {
                this.courseModules[coIndex].validation = true;
                this.courseModules[coIndex].id = cModule.value;
              }
            });
          }
        });
        this.value = this.value + 5;

        await this.checkCsv.forEach((cOData, coIndex) => {
          let excelDate = null;
          // console.log(moment(new Date(cOData[2])).isValid());
          if (cOData[2] && cOData[2] != '') excelDate = cOData[2];
          // console.log(new Date(cOData[2]));
          let levels = [];
          if (cOData[6] != null && cOData[6] != '')
            if (cOData[6].length != 0) {
              let tempLevels = cOData[6].split(",");
              tempLevels.forEach(cogLevel => {
                if (this.cognitive_levels_options.includes(cogLevel.trim())) levels.push(cogLevel)
              });
            }

          this.excelTable.push({
            si_no: cOData[0],
            lesson: cOData[1],
            planned_dates: excelDate,
            required_hours: !isNaN(cOData[3]) ? cOData[3] : null,
            course_outcomes:
              this.courseOutcomes[coIndex] && this.courseOutcomes[coIndex].validation
                ? this.courseOutcomes[coIndex].id
                : null,
            course_module_id:
              this.courseModules[coIndex] && this.courseModules[coIndex].validation
                ? this.courseModules[coIndex].id
                : null,
            cognitive_levels: levels,
          });
        });

        this.loadData = false;
        this.value = 50;
      }
      this.$forceUpdate();
    },
    async manageOutcomes() {
      // let status = null;
      for (let coIndex in this.checkCsv) {
        await this.courseOutcomesOptions.forEach((outcomeFromOption) => {
          let optionText = outcomeFromOption.text;

          this.courseOutcomes[coIndex]["excelOutcomeData"].forEach((outcomeFromExcel) => {
            if (`${outcomeFromExcel}`.localeCompare(optionText) == 0) {
              this.courseOutcomes[coIndex].validation = true;
              this.courseOutcomes[coIndex]["id"].push(outcomeFromOption);
            }
          });
        });
      }

      // status = true;
      // return status;
    },
    getCourseOutcomes() {
      const courseId = this.$route.params.course_id;
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseOutcomeUrl.replace("course_id", courseId);
      axios
        .get(url)
        .then((response) => {
          this.courseOutcomesOptions = [
            { value: null, text: "Select Outcome", disabled: true },
          ];
          let outcomes = response.data;
          if (outcomes.length != 0) {
            outcomes.forEach((cOutcome) => {
              this.courseOutcomesOptions.push({
                value: cOutcome.id,
                text: cOutcome.code,
              });
            });
          }
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
    getCourseModules() {
      const courseId = this.$route.params.course_id;
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", courseId);
      axios
        .get(url)
        .then((response) => {
          this.courseModulesOptions = [
            { value: null, text: "Select Module", disabled: true },
          ];
          let modules = response.data;
          if (modules.length != 0) {
            modules.forEach((cModule) => {
              this.courseModulesOptions.push({
                value: cModule.id,
                text: cModule.module,
              });
            });
          }
          this.fetchData = false;
          // console.log(response);
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
  },
};
</script>

<style >
.selectExcelFile i {
  font-size: 20px !important;
}
</style>
